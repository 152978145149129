import { gql } from '@apollo/client/core';

export const ORDER_PROCESSES_QUERY = gql`
  query orderProcess($customerId: ID, $finalized: Boolean, $first: Int, $after: Int) {
    orderProcesses(customerId: $customerId, finalized: $finalized, first: $first, after: $after) {
      nodes {
        id
        customerId
        items {
          id
          productId
          name
          sku
          quantity
        }
        supplier {
          id
          name
        }
        openedAt
        openedByUser {
          profile {
            firstName
            lastName
            profilePicture
          }
        }
        arrivesAt
        finalizedAt
        finalized
        generatedPdfSignedUrl
        dispatches {
          channel
          address
          status
          receivedAt
        }
        dispatchedAt
        dispatchedBy
        dispatchedByUser {
          profile {
            firstName
            lastName
            profilePicture
          }
        }
      }
      totalCount
    }
  }
`;

export const ORDER_PROCESSES_BY_ID_QUERY = gql`
  query orderProcessById($orderId: ID) {
    orderProcessById(orderId: $orderId) {
      id
      customerId
      items {
        id
        productId
        name
        sku
        quantity
        product {
          name
          bom {
            uom
            quantity
            orderUnit
            billingUnit
            bom {
              uom
              quantity
              orderUnit
              billingUnit
              bom {
                uom
                quantity
                orderUnit
                billingUnit
              }
            }
          }
        }
      }
      openedAt
      updatedAt
      arrivesAt
      finalizedAt
      finalized
      orderNumber
      dispatches {
        status
      }
      openedByUser {
        profile {
          firstName
          lastName
          profilePicture
        }
      }
      generatedPdfSignedUrl
      supplier {
        id
        name
      }
      note
    }
  }
`;

export const PURCHASE_LIST_ITEMS_QUERY = gql`
  query purchaseListItems($businessId: ID!, $supplierId: ID, $isRecommended: Boolean, $first: Int, $after: Int) {
    purchaseListItems(
      businessId: $businessId
      supplierId: $supplierId
      isRecommended: $isRecommended
      first: $first
      after: $after
    ) {
      nodes {
        id
        businessId
        supplierId
        productId
        isRecommended
        product {
          id
          name
          sku
        }
        supplier {
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const SUPPLIER_PURCHASE_LIST_ITEMS = gql`
  query supplierPurchaseListItems($businessId: ID!, $supplierId: ID, $first: Int, $after: Int) {
    purchaseListItems(businessId: $businessId, supplierId: $supplierId, first: $first, after: $after) {
      nodes {
        id
        businessId
        supplierId
        productId
        product {
          id
          name
          sku
          bom {
            quantity
            uom
            orderUnit
            billingUnit
            bom {
              quantity
              uom
              orderUnit
              billingUnit
              bom {
                quantity
                uom
                orderUnit
                billingUnit
              }
            }
          }
        }
        isRecommended
      }
      totalCount
    }
  }
`;

export const RESTOCK_PRODUCTS_LISTS_QUERY = gql`
  query restockProductsLists($businessId: ID!, $supplierId: ID, $first: Int, $after: Int) {
    restockProductsLists(businessId: $businessId, supplierId: $supplierId, first: $first, after: $after) {
      nodes {
        id
        businessId
        supplierId
        updatedAt
        products {
          productId
          maxStockLevel
        }
        supplier {
          id
          name
        }
      }
      totalCount
    }
  }
`;
