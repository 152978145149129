import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { PURCHASE_LIST_ITEMS_QUERY } from './graphql';

export function usePurchaseListItems({ currentTenant, supplierId, isRecommended, first, after }) {
  const { error } = useNotification();
  const variables = computed(() => ({
    businessId: currentTenant.value.id,
    supplierId: supplierId?.value ? supplierId.value : supplierId,
    isRecommended,
    first,
    after: after ? after.value : null,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(PURCHASE_LIST_ITEMS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const purchaseListItems = computed(() => {
    if (result.value) {
      return result.value.purchaseListItems;
    }

    return { nodes: [], totalCount: 0 };
  });

  onError((err) => {
    console.error('usePurchaseListItems', err);
    error();
  });

  return {
    purchaseListItems,
    loading,
    refetch,
    onResult,
  };
}
