import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useCreateRestockProductsList = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(CREATE_RESTOCK_PRODUCTS_LIST);

  onError((err) => {
    $message.error($t('errors.action'));
    console.error('orderProcess - useCreateRestockProductsList', err);
  });

  return {
    createRestockProductsList: mutate,
    onError,
    onDone,
    loading,
  };
};

const CREATE_RESTOCK_PRODUCTS_LIST = gql`
  mutation createRestockProductsList($createPayload: CreateRestockProductsListInput) {
    createRestockProductsList(createPayload: $createPayload) {
      success
    }
  }
`;
