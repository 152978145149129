import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

import { ORDER_PROCESSES_BY_ID_QUERY } from './graphql';

export function useOrderProcessById({ orderId }) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(
    ORDER_PROCESSES_BY_ID_QUERY,
    () => ({ orderId }),
    () => ({ fetchPolicy: 'no-cache' })
  );

  const orderProcessById = computed(() => result?.value || {});

  onError((err) => {
    console.error('useOrderProcessById', err);
    error();
  });

  return {
    orderProcessById,
    loading,
    refetch,
    onResult,
  };
}
