import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { ORDER_PROCESSES_QUERY } from './graphql';

export function useOrderProcesses({ currentTenant, finalized, first, after, activeTab, valueToCheckActiveTab }) {
  const { error } = useNotification();
  const variables = computed(() => ({
    customerId: currentTenant.value.id,
    finalized,
    first,
    after: after ? after.value : null,
    isTabActive: activeTab && valueToCheckActiveTab ? activeTab.value === valueToCheckActiveTab : null,
  }));

  const { result, loading, onError, refetch } = useQuery(ORDER_PROCESSES_QUERY, variables, () => ({
    enabled: !!variables.value.customerId && variables.value.isTabActive,
  }));

  const orderProcesses = useResult(result, { nodes: [], totalCount: 0 });

  onError((err) => {
    console.error('useOrderProcesses', err);
    error();
  });

  return {
    orderProcesses,
    loading,
    refetch,
  };
}
