<template>
  <div>
    <div class="d-flex justify-content-between mb-5">
      <h3>{{ $t('orders.restockProductsLists.title') }}</h3>
    </div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-else
      border
      rounded
      :data="restockProductsLists"
      :columns="columns"
      :show-index="currentIndex"
      @row-click="clickedOnSupplierRestockProductsList"
    >
      <template
        #cell-supplier="{
          rowData: {
            supplier: { name },
          },
        }"
      >
        <p>{{ name }}</p>
      </template>
      <template #cell-updatedAt="{ rowData: { updatedAt } }">
        <p v-if="updatedAt">{{ formatDuration(updatedAt) }}</p>
        <p v-else class="missing-text">{{ MISSING_DATA_TEXT }}</p>
      </template>
    </Table>
    <el-pagination
      class="my-2 float-right pb-4"
      layout="prev, pager, next, jumper"
      small
      hide-on-single-page
      background
      :page-size="limit"
      :total="total"
      :page-count="pageCount"
      @current-change="onPageChanged"
    />
    <div>
      <RestockProductsListModal
        v-if="productListToShow"
        :product-list="productListToShow"
        :supplier-name="supplierName"
        :restock-products-list-id="restockProductsListId"
        :submit-loading="submitLoading"
        @on-submit="onSubmitRestockProductsList"
        @on-cancel="onCancelRestockProductList"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { Table, TableLoadingSkeleton } from '@/modules/core/components';
import { formatDuration, returnDashInsteadOfNull } from '@/modules/purchase-management/purchaseManagementFormatters';
import { getRestockProductsListsColumns } from '../orderProcessesColumns';
import { usePatchRestockProductsList } from '../../compositions/usePatchRestockProductsList';
import { useCreateRestockProductsList } from '../../compositions/useCreateRestockProductsList';
import RestockProductsListModal from './RestockProductsListModal';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  name: 'RestockProductsListTable',
  components: { Table, TableLoadingSkeleton, RestockProductsListModal },
  props: {
    currentTenantId: { type: String, required: true },
    restockProductsLists: { type: Array, required: true },
    suppliersPurchaseListProducts: { type: Object, required: true },
    limit: { type: Number, required: true },
    page: { type: Number, required: true },
    total: { type: Number, required: true },
    loading: { type: Boolean, required: true },
  },
  emits: ['on-page-changed'],
  setup(props, { emit }) {
    const columns = getRestockProductsListsColumns();

    const root = getCurrentInstance().proxy;
    const restockProductsListId = ref(null);
    const productListToShow = ref(null);
    const supplierName = ref(null);
    const activeSupplierId = ref(null);

    const {
      patchRestockProductsList,
      loading: patchRestockProductsListLoading,
      onDone: onPatchDone,
    } = usePatchRestockProductsList();
    const {
      createRestockProductsList,
      loading: createRestockProductsListLoading,
      onDone: onCreateDone,
    } = useCreateRestockProductsList();

    const currentIndex = computed(() => (props.page - 1) * props.limit + 1);
    const pageCount = computed(() => Math.ceil(props.total / props.limit));

    const sortedListsBySuppliers = computed(() => {
      const sortedProductsBySupplierId = {};
      const supplierIdsToIterate = Object.keys(props.suppliersPurchaseListProducts);

      supplierIdsToIterate.forEach((supplierId) => {
        const supplierPurchaseListProducts = props.suppliersPurchaseListProducts[supplierId].map(
          ({ product }) => product
        );
        sortedProductsBySupplierId[supplierId] = sortedProductsBySupplierId[supplierId] || [];
        const relevantRestockProductsList = props.restockProductsLists.find(
          (restockProductsList) => restockProductsList.supplierId === supplierId
        );
        if (relevantRestockProductsList && relevantRestockProductsList.products) {
          const products = relevantRestockProductsList.products;

          products.forEach((product) => {
            const relevantProductIndex = supplierPurchaseListProducts.findIndex(({ id }) => product.productId === id);

            if (relevantProductIndex !== -1) {
              sortedProductsBySupplierId[supplierId].push({
                ...supplierPurchaseListProducts[relevantProductIndex],
                maxStockLevel: product.maxStockLevel,
              });
              supplierPurchaseListProducts.splice(relevantProductIndex, 1);
            }
          });
        }
        if (supplierPurchaseListProducts.length > 0) {
          sortedProductsBySupplierId[supplierId].push(
            ...supplierPurchaseListProducts.map((product) => ({ ...product, maxStockLevel: undefined }))
          );
        }
      });

      return sortedProductsBySupplierId;
    });

    const submitLoading = computed(
      () => patchRestockProductsListLoading.value || createRestockProductsListLoading.value
    );

    const onPageChanged = (page) => {
      emit('on-page-changed', { page });
    };

    const onDoneCallback = () => {
      root.$message.success(root.$t('orders.restockProductsLists.mutateSuccess'));
      productListToShow.value = null;
      emit('on-refetch');
    };

    onPatchDone(onDoneCallback);
    onCreateDone(onDoneCallback);

    const onCancelRestockProductList = () => {
      productListToShow.value = null;
    };

    const clickedOnSupplierRestockProductsList = (rowIndex) => {
      productListToShow.value = sortedListsBySuppliers.value[props.restockProductsLists[rowIndex].supplierId];
      activeSupplierId.value = props.restockProductsLists[rowIndex].supplierId;
      supplierName.value = props.restockProductsLists[rowIndex].supplier.name;
      restockProductsListId.value = props.restockProductsLists[rowIndex].id;
    };

    const onSubmitRestockProductsList = ({ id, products }) => {
      if (id) {
        patchRestockProductsList({
          restockProductsListId: id,
          patchPayload: {
            products: products.map(({ id, maxStockLevel }) => ({
              productId: id,
              maxStockLevel: maxStockLevel ? Number(maxStockLevel) : undefined,
            })),
          },
        });
      } else {
        createRestockProductsList({
          createPayload: {
            businessId: props.currentTenantId,
            supplierId: activeSupplierId.value,
            products: products.map(({ id, maxStockLevel }) => ({
              productId: id,
              maxStockLevel: maxStockLevel ? Number(maxStockLevel) : undefined,
            })),
          },
        });
      }
    };

    return {
      MISSING_DATA_TEXT,
      columns,
      pageCount,
      currentIndex,
      supplierName,
      submitLoading,
      productListToShow,
      restockProductsListId,
      sortedListsBySuppliers,
      onPageChanged,
      onCancelRestockProductList,
      onSubmitRestockProductsList,
      clickedOnSupplierRestockProductsList,
      formatDuration: (date) => returnDashInsteadOfNull(formatDuration(date)),
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.missing-text {
  color: $typography-secondary;
}
</style>
