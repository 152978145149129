import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { RESTOCK_PRODUCTS_LISTS_QUERY } from './graphql';

export function useRestockProductsLists({ currentTenant, supplierId, first, after }) {
  const { error } = useNotification();
  const variables = computed(() => ({
    businessId: currentTenant.value.id,
    supplierId: supplierId,
    first,
    after: after ? after.value : null,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(RESTOCK_PRODUCTS_LISTS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const restockProductsLists = computed(() => {
    if (result.value) {
      return result.value.restockProductsLists;
    }

    return { nodes: [], totalCount: 0 };
  });

  onError((err) => {
    console.error('useRestockProductsLists', err);
    error();
  });

  return {
    restockProductsLists,
    loading,
    refetch,
    onResult,
  };
}
