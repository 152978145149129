import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const usePatchRestockProductsList = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(PATCH_RESTOCK_PRODUCTS_LIST);

  onError((err) => {
    $message.error($t('errors.action'));
    console.error('orderProcess - usePatchRestockProductsList', err);
  });

  return {
    patchRestockProductsList: mutate,
    onError,
    onDone,
    loading,
  };
};

const PATCH_RESTOCK_PRODUCTS_LIST = gql`
  mutation patchRestockProductsList($restockProductsListId: ID!, $patchPayload: PatchRestockProductsListInput) {
    patchRestockProductsList(restockProductsListId: $restockProductsListId, patchPayload: $patchPayload) {
      success
    }
  }
`;
