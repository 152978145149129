import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useDeleteOrderProcess = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(DELETE_ORDER_PROCESS);

  onError((err) => {
    $message.error($t('orders.table.deleteActiveOrder.fail'));
    console.error('orderProcess - useDeleteOrderProcess', err);
  });

  return {
    deleteOrderProcess: mutate,
    onError,
    onDone,
    loading,
  };
};

const DELETE_ORDER_PROCESS = gql`
  mutation deleteOrderProcess($orderId: ID!) {
    deleteOrderProcess(orderId: $orderId) {
      success
    }
  }
`;
