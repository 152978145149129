import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { SUPPLIER_PURCHASE_LIST_ITEMS } from './graphql';

export function useSupplierPurchaseListItems({ currentTenant, supplierId, first, after }) {
  const { error } = useNotification();
  const variables = computed(() => ({
    businessId: currentTenant.value.id,
    supplierId: supplierId.value,
    first,
    after: after ? after.value : null,
  }));

  const { result, loading, onError, refetch, onResult } = useQuery(SUPPLIER_PURCHASE_LIST_ITEMS, variables, () => ({
    enabled: !!(variables.value.businessId && variables.value.supplierId),
  }));

  const purchaseListItems = computed(() => {
    if (result.value) {
      return result.value.purchaseListItems;
    }

    return { nodes: [], totalCount: 0 };
  });

  onError((err) => {
    console.error('useSupplierPurchaseListItems', err);
    error();
  });

  return {
    purchaseListItems,
    loading,
    refetch,
    onResult,
  };
}
