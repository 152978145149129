import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useCreateOrderProcess = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(CREATE_ORDER_PROCESSES);

  onError((err) => {
    $message.error($t('errors.action'));
    console.error('orderProcess - useCreateOrderProcess', err);
  });

  return {
    createOrderProcess: mutate,
    onError,
    onDone,
    loading,
  };
};

const CREATE_ORDER_PROCESSES = gql`
  mutation orderProcess($customerId: ID, $supplierId: ID, $arrivesAt: Timestamp) {
    orderProcess(customerId: $customerId, supplierId: $supplierId, arrivesAt: $arrivesAt) {
      id
      supplierId
      supplier {
        id
        name
      }
    }
  }
`;
